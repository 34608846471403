import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { FCC } from '~/core/@types/global'
import { LinkButton } from '~/core/ui/LinkButton'
import { TypographyText } from '~/core/ui/Text'
import configuration from '~/configuration'
import DropdownLanguage from '../DropdownLanguage'
import { TextButton } from '~/core/ui/TextButton'
import { cn } from '~/core/ui/utils'
import { ISelectOption } from '~/core/ui/Select'

interface LayoutFooterPowerBy {
  background?: string
  showSwitchLanguages?: boolean
  languagesOptions?: ISelectOption[]
}

const LayoutFooterPowerBy: FCC<LayoutFooterPowerBy> = (props) => {
  const {
    children,
    background = '',
    showSwitchLanguages = false,
    languagesOptions
  } = props
  const { t } = useTranslation()
  const router = useRouter()
  const { id } = router.query

  return (
    <div className={background}>
      {children}

      <div className="border-t border-t-gray-100">
        <div
          className={cn(
            'mx-auto flex w-full max-w-[1248px] flex-col items-center px-4 py-4 tablet:flex-row tablet:py-6',
            showSwitchLanguages
              ? 'tablet:justify-between'
              : 'tablet:justify-center'
          )}>
          <div className="mb-4 flex justify-center space-x-1 tablet:mb-0">
            <TypographyText className="text-gray-500 dark:text-gray-300">
              {t('label:powered_by')}
            </TypographyText>

            <TextButton
              label={configuration.appDomain}
              size="lg"
              underline={false}
              onClick={() => {
                window.open(
                  id
                    ? `${configuration.appUrl}?utm_campaign=careers_page&utm_content=${id}&utm_source=careers_page`
                    : configuration.appUrl
                )
              }}
            />
          </div>
          {showSwitchLanguages && (
            <DropdownLanguage languagesOptions={languagesOptions} />
          )}
        </div>
      </div>
    </div>
  )
}

export default LayoutFooterPowerBy
