export const DEPARTMENT_SHOW_TOP_LEVEL = 'show_top_level'
export const DEPARTMENT_SHOW_ALL = 'show_all'

export const LINK_FIND_GA_ID =
  'https://support.google.com/analytics/answer/9539598#find-G-ID'

export const LANGUAGES_VALUE = {
  english: 'en',
  japanese: 'ja'
}
